import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { loadStripe } from '@stripe/stripe-js'
import {
  ExpressCheckoutElement,
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'

import './index.css'

const searchParams = new URLSearchParams(window.location.search)
const customerId = searchParams.get("customerId")

const CheckoutForm = ({ name, email }) => {
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState(null)

  const [isLoading, setIsLoading] = React.useState(false)

  const handleSubmit = async () => {
    if (elements == null) {
      return
    }

    setIsLoading(true)

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit()
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message)
      setIsLoading(false)
      return
    }

    const {error} = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'https://sigmaos.com/payment-confirmed'
      },
      redirect: "if_required"
    })

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setIsLoading(false)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setIsLoading(false)

      await fetch('https://api.sigmaos.com/create-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          customerId: customerId,
          priceId: "price_1OyBVSEPbhcaA0r3Mr0zD3Mk",
          version: 1
        })
      })

      window.location.href = 'https://sigmaos.com/payment-confirmed'
    }
  }

  window.sigmaos_submitPaymentForm = handleSubmit

  return (
    <form onSubmit={handleSubmit}>
      <ExpressCheckoutElement
        options={
          {
            buttonType: {
              applePay: "subscribe",
              googlePay: "subscribe"
            },
            paymentMethods: {
              link: 'never'
            }
          }
        }
        onClick={ ({resolve}) => { resolve({billingAddressRequired: true}) } }
        onConfirm={handleSubmit}
      />
      <div id="card">
        <div>
          <PaymentElement
            options={
              {
                defaultValues: {
                  billingDetails: {
                    name,
                    email: email && !email.includes("privaterelay.apple.com") ? email : undefined
                  }
                }
              }
            }
          />
          {/* Show error message to your customers */}
          {errorMessage && <div>{errorMessage}</div>}


          <div className="message">
            At the end of your trial, you will be automatically upgraded to the full price of $20 per month.
          </div>
        </div>
        {isLoading && (
          <div id="overlay">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </form>
  );
};

const stripePromise = loadStripe('pk_live_51J703DEPbhcaA0r3kCTED0mtBJGgnm4lkCCkkIPPJ0Pvgjr9xKc05Yct3Ga4iRRatLTGg571kiHpXb0mD6lVn8Dj00zNl9c2as');

const App = () => {
  const [configuration, setConfiguration] = useState()
  useEffect(() => {
    (async function() {
      const res = await fetch('https://api.sigmaos.com/create-setup-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          customerId: customerId
        })
      })
      const body = await res.json()
      setConfiguration(body)
    })()
  }, [])

  const [darkMode, setDarkMode] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches)
  useEffect(() => {
    const listener = (e) => {
      setDarkMode(e.matches)
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', listener)

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', listener)
    }
  }, [])

  const palette = {
    fig: darkMode ? "#9C79FF" : "#C0AEFF",
  }

  const options = {
    clientSecret: configuration && configuration.clientSecret,
    // Fully customizable with appearance API.
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false
    },
    wallets: {
      applePay: 'auto',
      googlePay: 'auto'
    },
    appearance: {
      labels: "floating",
      theme: darkMode ? "night" : "flat",
      variables: {
        borderRadius: '8px',
        colorPrimary: palette.fig,
      },
      rules: {
        '.Input': {
          border: 0
        },
        '.Input:focus': {
          outline: '1px solid var(--colorPrimary)',
          boxShadow: 'none'
        }
      }
    },
  };

  return (
    configuration &&
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm name={configuration.name} email={configuration.email} />
    </Elements>
  )
}

if (customerId) {
  const container = document.getElementById("root")
  const root = createRoot(container)
  root.render(<App />)
}
